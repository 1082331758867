import { TopLevelMenuItem } from './menu';
import { Category } from '../generated-interfaces/graphql';
import {
  IBrandMenu,
  IMUItem,
  IOrderConstraint,
  IOrderItem,
  IRestaurantUnavailableEntity,
} from '../types/brandMenu';
import { CartItem, CartModifierGroup } from './cart';
import { CartItemAdditionTypes } from '../constants/event';
import {
  convertToMapUsingName,
  getRestaurantGroups,
  populateItemAvailability,
} from './menuUnification.utils';
import { TTS_PROMPT, UPSELL } from '../constants';
import {
  UNIFIED_MENU_CODE_NAME_MAPPING,
  UNIFIED_MENU_MOD_SYMBOL_MAPPING,
} from './menuUnification.constants';

export const convertToBrandMenuCategories = (
  categories: Category[],
  brandMenu: IBrandMenu
) => {
  const brandMenuItems: string[] = [];
  brandMenu.items.forEach((item: IMUItem) => brandMenuItems.push(item.name));
  return [
    { ...categories[0], name: 'Support Brand Menu', menuItems: brandMenuItems },
  ];
};

export const getMenuModuleConstraints = (
  children: CartModifierGroup,
  cartItemsQuantity: Record<string, number>
) => {
  const selectedItems = Object.values(children.selectedItems).reduce(
    (acc, item) => {
      acc.push(convertToMenuModuleOrder(item, cartItemsQuantity, true));
      return acc;
    },
    [] as IOrderItem[]
  );
  return {
    constraint_name: children.name,
    items: selectedItems as IOrderItem[],
  } as IOrderConstraint;
};

export const fillConstraints = (
  cartItem: CartItem,
  cartItemsQuantity: Record<string, number>,
  isModifier: boolean = false
) => {
  const { childModifierGroups, modcode = '', addedBy } = cartItem;

  if (Object.values(childModifierGroups).length) {
    return Object.values(childModifierGroups).reduce((acc, child) => {
      acc.push(getMenuModuleConstraints(child, cartItemsQuantity));
      return acc;
    }, [] as IOrderConstraint[]);
  } else if (modcode) {
    return [
      {
        constraint_name: 'ingredient_qty',
        items: [
          {
            item: UNIFIED_MENU_CODE_NAME_MAPPING[modcode]?.name,
            quantity: isModifier
              ? 1
              : cartItemsQuantity[cartItem.cartItemId] || 1,
            added_by: addedBy || CartItemAdditionTypes.human,
          },
        ],
      },
    ];
  }

  return undefined;
};

export const convertToMenuModuleOrder = (
  cartItem: CartItem,
  cartItemsQuantity: Record<string, number>,
  isModifier: boolean = false
) => {
  return {
    item: cartItem.name,
    quantity: isModifier ? 1 : cartItemsQuantity[cartItem.cartItemId] || 1,
    constraints: fillConstraints(cartItem, cartItemsQuantity, isModifier),
    added_by: cartItem.addedBy || CartItemAdditionTypes.human,
  } as IOrderItem;
};

export const buildUnifiedMenuModuleOrder = (
  cartItem: CartItem,
  cartItemsQuantity: Record<string, number>,
  isModifier: boolean = false
) => {
  const menuModuleItems = convertToMenuModuleOrder(
    cartItem,
    cartItemsQuantity,
    isModifier
  );
  if (cartItem.name.includes(UPSELL)) {
    return menuModuleItems?.constraints?.[0]?.items?.[0];
  }
  return menuModuleItems;
};

export const processBrandMenu = (
  brandMenu: IBrandMenu,
  restaurantCode: string,
  restaurantUnavailableEntities: IRestaurantUnavailableEntity[]
) => {
  const brandUnavailableEntities = brandMenu.unavailable_entities;
  const items = convertToMapUsingName(brandMenu.items);
  const restaurantGroups = getRestaurantGroups(
    restaurantCode,
    brandMenu.restaurant_groups
  ); // TODO: store in redux to reuse for processing the constraint group and options in buildFullMenuItems
  const unavailableItemsByName = {
    ...convertToMapUsingName(
      brandUnavailableEntities.filter((unavailableEntity) =>
        restaurantGroups.includes(unavailableEntity.restaurant_group)
      )
    ),
    ...convertToMapUsingName(restaurantUnavailableEntities),
  };

  let topLevelMenuItems: TopLevelMenuItem[] = [];
  brandMenu.categories.forEach((category) => {
    const { item_names: categoryItemNames, name: categoryName } =
      category || {};
    categoryItemNames.forEach((itemName) => {
      const item = items[itemName];
      if (item) {
        const { shouldAddItem, available, unavailableUntil } =
          populateItemAvailability(unavailableItemsByName[itemName]);

        if (shouldAddItem) {
          const topLevelMenuItemName =
            categoryName.includes(TTS_PROMPT) || categoryName.includes(UPSELL)
              ? `${item.name}:${item.description}`
              : item.name;
          topLevelMenuItems.push({
            category: category.name,
            categoryId: category.name,
            id: item.name,
            name: topLevelMenuItemName,
            description: item.description,
            available,
            unavailableUntil,
          });
        }
      }
    });
  });

  return {
    topLevelMenuItems,
    brandMenu,
    restaurantUnavailableEntities,
    // Hardcode the following fields for compatibility
    processedCategoryAndTimePeriodJSON: {},
    processedMenuJSON: {},
    processedModGroupsJSON: {},
    categoriesWithTimePeriod: [],
    availableCategoryWithTimePeriod: [],
    alwaysAvailableCategories: [],
    persistentVoiceProps: {},
    codeNameMapping: UNIFIED_MENU_CODE_NAME_MAPPING,
    modSymbolMapping: UNIFIED_MENU_MOD_SYMBOL_MAPPING,
    unavailableItems: {},
    autoComboPrpIds: [],
    comboPrpIdToALaCartePrpId: {},
  };
};
