import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {
  IBrandMenu,
  IConstraint,
  IMUItem,
  IRestaurantGroup,
  IRestaurantUnavailableEntity,
  IUnavailableEntity,
} from '../types/brandMenu';
import { GenericMap } from './types';
import { TopLevelMenuItem } from './menu';
import {
  DUMMY_MENU_ITEM,
  DUMMY_MODIFIER_GROUP,
} from '../redux/features/menuUnification/menuUnification.constants';
import { convertToMap } from '.';
import { ParsedMenuItem, ParsedModifierGroup } from '../types/menu';
import { UPSELL } from '../constants';

export function convertToMapUsingName<T extends { name: string }>(
  entries: T[]
): GenericMap<T> {
  return entries.reduce((acc, entry) => {
    acc[entry.name] = entry;
    return acc;
  }, {} as GenericMap<T>);
}

export const getRestaurantGroups = (
  restaurantCode: string,
  brandMenuRestaurantGroup: IRestaurantGroup[]
) => {
  return brandMenuRestaurantGroup
    .filter((restaurantGroup) =>
      restaurantGroup.restaurant_codes.includes(restaurantCode)
    )
    .reduce((acc, restaurantGroup) => {
      acc.push(restaurantGroup.name);
      return acc;
    }, [] as string[]);
};

export const populateItemAvailability = (entry: IUnavailableEntity) => {
  let shouldAddItem = true;
  let available = true;

  const { effective_starting, effective_until: unavailableUntil } = entry || {};
  const currentDateTime = new Date();
  if (
    effective_starting &&
    moment(effective_starting).isBefore(currentDateTime)
  ) {
    if (!unavailableUntil) {
      available = false;
      shouldAddItem = false;
    } else if (moment(unavailableUntil).isAfter(currentDateTime)) {
      available = false;
    }
  }

  return {
    shouldAddItem,
    available,
    unavailableUntil,
  };
};

const parseROPMenuItem = ({
  brandMenuItems,
  brandMenuConstraints,
  unavailableItemsByName,
  childItem,
}: {
  brandMenuItems: Record<string, IMUItem>;
  brandMenuConstraints: Record<string, IConstraint>;
  unavailableItemsByName: Record<string, IUnavailableEntity>;
  childItem: string;
}): ParsedMenuItem => {
  const { available, unavailableUntil } = populateItemAvailability(
    unavailableItemsByName[childItem]
  );
  return {
    ...DUMMY_MENU_ITEM,
    id: childItem,
    name: childItem,
    itemId: childItem,
    category: 'modifier',
    categoryId: uuidv4(),
    available,
    unavailableUntil,
    modifierGroups: convertToMap(
      brandMenuItems[childItem].constraints.map((constraint) =>
        parseROPModifierGroup({
          brandMenuItems,
          brandMenuConstraints,
          unavailableItemsByName,
          constraint,
        })
      )
    ),
  };
};

const parseROPModifierGroup = ({
  brandMenuItems,
  brandMenuConstraints,
  unavailableItemsByName,
  constraint,
}: {
  brandMenuItems: Record<string, IMUItem>;
  brandMenuConstraints: Record<string, IConstraint>;
  unavailableItemsByName: Record<string, IUnavailableEntity>;
  constraint: string;
}): ParsedModifierGroup => {
  const constraintGroup = brandMenuConstraints[constraint] as IConstraint;
  const childItems = constraintGroup.child_items;
  const itemsToAdd = childItems.map((childItem: string) => ({
    ...parseROPMenuItem({
      brandMenuItems,
      brandMenuConstraints,
      unavailableItemsByName,
      childItem,
    }),
    parentModifierGroupName: constraintGroup.name,
    parentModifierGroupDisplayName: constraintGroup.name,
    parentModifierGroupId: constraintGroup.name,
  }));

  return {
    ...DUMMY_MODIFIER_GROUP,
    id: constraint,
    name: constraint,
    menuItems: convertToMap(itemsToAdd),
    posProperties: {},
    minimumSelections: constraintGroup.min,
    maximumSelections: constraintGroup.max,
    defaultSelectedItemIds: constraintGroup.default_items,
    description: constraintGroup.prompt,
  };
};

export const buildFullMenuUnifiedItem = ({
  item,
  brandMenu,
  restaurantCode,
  restaurantUnavailableEntities,
}: {
  item: TopLevelMenuItem;
  brandMenu: IBrandMenu | undefined;
  restaurantCode: string;
  restaurantUnavailableEntities: IRestaurantUnavailableEntity[];
}) => {
  let modifierGroups: ParsedModifierGroup[] = [];
  if (brandMenu) {
    const brandUnavailableEntities = brandMenu.unavailable_entities;
    const brandMenuItems = convertToMapUsingName(brandMenu.items);
    const brandMenuConstraints = convertToMapUsingName(brandMenu.constraints);
    const restaurantGroups = getRestaurantGroups(
      restaurantCode,
      brandMenu.restaurant_groups
    );
    const unavailableItemsByName = {
      ...convertToMapUsingName(
        brandUnavailableEntities.filter((unavailableEntity) =>
          restaurantGroups.includes(unavailableEntity.restaurant_group)
        )
      ),
      ...convertToMapUsingName(restaurantUnavailableEntities),
    };

    const itemName = item.name?.includes(UPSELL)
      ? item.name.split(':')?.[0]
      : item.name;

    const constraints = brandMenuItems[itemName].constraints;
    constraints.forEach((constraint) => {
      const { available } = populateItemAvailability(
        unavailableItemsByName[constraint]
      );
      if (available) {
        modifierGroups.push(
          parseROPModifierGroup({
            brandMenuItems,
            brandMenuConstraints,
            unavailableItemsByName,
            constraint,
          })
        );
      }
    });
  }

  return {
    ...DUMMY_MENU_ITEM,
    ...item,
    modifierGroups: convertToMap(modifierGroups),
  };
};
